'use strict';

var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('./login/login'));
});

$('.btnToRegistration').on('click',function(){
   $("#register").css("display", "block");
   $("#login").css("display", "none");
})

$('.btnToLogin').on('click',function(){
   $("#login").css("display", "block");
   $("#register").css("display", "none");
   $("html, body").scrollTop(0);
})